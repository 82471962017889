:root {
  --text-on-dark-1: #fff;
  --text-on-dark-2: #ccc;
  --text-on-bright: #5b5b5b;
  --highlight-on-dark: #9e8576;
  --hero-bg-color: #0c2447;
  --highlight-on-dark: #eccc5a;
  --heading-on-bright: #9e8576;
  --dark-accent-on-bright: #04395e;
  --menu-active-time: .35s;
  --dark-bg: #192c46;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, p, ul, li {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, p, a, li {
  color: var(--text-on-bright);
  font-family: Readex Pro, sans-serif;
  font-weight: 300;
}

p, a, li {
  font-size: 1.125rem;
  line-height: 1.8rem;
}

.text-on-dark {
  color: var(--text-on-dark-2);
}

.link-on-dark {
  transition: color .25s ease-in;
}

.link-on-dark:hover {
  color: var(--highlight-on-dark);
}

.center {
  max-width: 80rem;
  margin: auto;
  padding: 0 2rem;
}

.main-content {
  padding: 2rem;
}

.main-content p {
  max-width: 56rem;
  color: var(--text-on-bright);
  margin: 1rem 0;
  font-size: 1rem;
  line-height: 1.8rem;
}

.main-content h3 {
  color: var(--heading-on-bright);
  margin: 3rem 0 2rem;
  font-family: Readex Pro, sans-serif;
  font-size: 1.875rem;
  font-weight: normal;
}

.main-content-highlighted {
  border-right: 10px var(--heading-on-bright) solid;
}

@media screen and (max-width: 64rem) {
  .main-content-highlighted {
    border-right: none;
  }
}

.button-container {
  overflow: hidden;
}

.button-on-dark {
  border: 2px var(--highlight-on-dark) solid;
  color: var(--highlight-on-dark);
  background: none;
  padding: .5rem 1rem;
  font-family: Barlow, sans-serif;
  display: block;
  position: relative;
}

.button-on-dark:hover, .button-on-dark:focus {
  color: #000;
}

.button-on-dark:after {
  content: "";
  background-color: var(--highlight-on-dark);
  width: calc(100% + 2rem);
  height: calc(100% + 4px);
  z-index: -1;
  transition: all .25s ease-out;
  position: absolute;
  top: -2px;
  left: -1rem;
  transform: skew(-10deg)translate3d(-100%, 0, 0);
}

.button-on-dark:hover:after, .button-on-dark:focus:after {
  transform: translate3d(0%, 0, 0);
}

.hero {
  background: url("torun.f18ee775.jpg") 0 0 / cover no-repeat;
  background-color: var(--hero-bg-color);
  height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.skip-nav-link {
  background-color: var(--highlight-on-dark);
  color: #000;
  text-transform: uppercase;
  z-index: 1;
  border: none;
  padding: .5rem 1rem;
  font-family: Barlow, sans-serif;
  text-decoration: none;
  transition: transform .25s ease-in;
  position: absolute;
  left: .5rem;
  transform: translateY(-101%);
}

.skip-nav-link:focus {
  transform: translateY(0);
}

.hero:before {
  content: "";
  background-color: var(--hero-bg-color);
  width: 100%;
  height: 100vh;
  opacity: .75;
  position: absolute;
  top: 0;
}

.hero:after {
  content: "";
  width: 100%;
  height: 100vh;
  background: linear-gradient(#0009, #0003, #0009);
  position: absolute;
  top: 0;
}

.hero-top {
  width: 90%;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem auto;
  padding: 0;
  display: flex;
}

.menu {
  width: 100%;
  justify-content: space-around;
  list-style: none;
  display: flex;
}

@media screen and (max-width: 320px) {
  .menu {
    gap: 1rem;
  }
}

.menu a {
  color: var(--text-on-dark-2);
  font-variant: small-caps;
  text-transform: uppercase;
  padding: .4em 0;
  font-family: Barlow, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
  .menu {
    width: unset;
    gap: 2rem;
    margin-left: 5%;
  }

  .menu a {
    font-size: 1.125rem;
  }
}

.menu a:hover, .menu a:focus {
  color: var(--highlight-on-dark);
  transition: color var(--menu-active-time);
}

.menu a:after {
  content: "";
  height: .2em;
  width: 100%;
  background-color: var(--highlight-on-dark);
  opacity: 1;
  transition: opacity var(--menu-active-time), transform var(--menu-active-time);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate3d(-101%, 0, 0);
}

.menu a:hover:after, .menu a:focus:after {
  transform: translate3d(0, 0, 0);
}

.hero-top .button-container {
  margin: -3rem auto 0;
  transform: translateY(6rem);
}

@media screen and (min-width: 640px) {
  .hero-top .button-container {
    margin-left: auto;
    margin-right: 5%;
    transform: translateY(1.5rem);
  }
}

.phone {
  min-width: 10rem;
  margin-right: 5%;
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-decoration: none;
}

.phone-icon {
  width: 1.5rem;
  height: 1.5rem;
  border-right: 1px var(--highlight-on-dark) solid;
  fill: var(--highlight-on-dark);
  margin-right: .6rem;
  padding-right: .6rem;
  display: inline-block;
  position: relative;
  top: .2rem;
}

.button-on-dark:hover .phone-icon, .button-on-dark:focus .phone-icon {
  fill: #000;
  border-right: 1px solid #000;
}

.hero-logo {
  z-index: 1;
  margin: 0 auto;
}

.hero-logo-name {
  border-bottom: 4px var(--highlight-on-dark) solid;
  border-bottom-width: clamp(2px, 1px + .25vw, 4px);
  font-family: Readex Pro, sans-serif;
  font-size: clamp(1rem, 1rem + 6vw, 6rem);
  font-weight: 400;
}

.hero-logo-title {
  font-variant: small-caps;
  letter-spacing: .1em;
  font-family: Playfair Display;
  font-size: clamp(1rem, .5rem + 4.5vw, 4rem);
  font-weight: 400;
}

.hero-logo-name, .hero-logo-title {
  color: var(--text-on-dark-1);
}

.hero-contact {
  z-index: 1;
  margin-bottom: 1rem;
  transform: translateY(-4rem);
}

.hero-contact .text-on-dark {
  margin: 0 1rem;
  line-height: 1.8rem;
}

@media screen and (max-width: 320px) {
  .hero-contact .text-on-dark {
    font-size: .875rem;
    line-height: 1.125rem;
  }
}

.main-content-boxed-list {
  flex-wrap: wrap;
  display: flex;
}

.main-content-boxed-list li {
  box-sizing: border-box;
  width: 100%;
  min-height: 5rem;
  border-left: 10px var(--dark-accent-on-bright) solid;
  color: var(--dark-accent-on-bright);
  background-color: #f6f3f2;
  flex-direction: column;
  place-content: center;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  line-height: 1.5rem;
  list-style: none;
  transition: transform .35s ease-in, opacity .35s ease-in;
  display: flex;
}

@media screen and (min-width: 800px) {
  .main-content-boxed-list li {
    width: calc(50% - .75rem);
    margin-right: 0;
  }

  .main-content-boxed-list li:nth-child(2n+1) {
    margin-right: 1.5rem;
    transition-delay: .2s;
  }
}

.contact-container {
  position: relative;
}

.contact-box {
  box-sizing: border-box;
  background-color: var(--dark-bg);
  padding: 2rem;
}

.contact-box h3 {
  color: var(--highlight-on-dark);
  padding-bottom: 1rem;
  font-size: 1.875rem;
}

.contact-box h4 {
  color: var(--text-on-dark-1);
  padding-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
}

.contact-box p {
  color: var(--text-on-dark-2);
}

.contact-box-email {
  padding-top: 1rem;
  display: inline-block;
}

.contact-box-parking {
  opacity: .6;
  padding-top: 1rem;
  font-size: .875rem;
  line-height: 1rem;
}

.contact-map {
  width: 100%;
  height: 29rem;
}

@media screen and (min-width: 57rem) {
  .contact-box-container {
    height: 29rem;
    top: 0;
  }

  .contact-box {
    box-sizing: border-box;
    background-color: var(--dark-bg);
    height: calc(100% - 4rem);
    margin-top: 2rem;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: calc(50% + 1.5rem);
  }

  .contact-box h3 {
    color: var(--highlight-on-dark);
    padding-bottom: 1rem;
    font-size: 1.875rem;
  }

  .contact-box h4 {
    color: var(--text-on-dark-1);
    padding-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .contact-map {
    width: 100%;
    height: 29rem;
    position: absolute;
  }
}

.slide-in--out {
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
}

.slide-in--in {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

footer {
  background-color: var(--dark-bg);
}

.footer-content {
  opacity: .8;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-content p {
  color: var(--text-on-dark-2);
  font-size: .875rem;
}

@media screen and (min-width: 57rem) {
  .footer-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

/*# sourceMappingURL=index.8dbc9a0b.css.map */
