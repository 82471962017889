:root {
  --text-on-dark-1: #fff;
  --text-on-dark-2: #ccc;
  --text-on-bright: #5b5b5b;
  --highlight-on-dark: #9e8576;
  --hero-bg-color: #0c2447;
  --highlight-on-dark: #eccc5a;
  --heading-on-bright: #9e8576;

  --dark-accent-on-bright: #04395e;

  --menu-active-time: 350ms;

  --dark-bg: #192c46;

  margin: 0;
  padding: 0;
}

/*
* reset
*/

h1,
h2,
h3,
h4,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

/*
* typography
*/

h1,
h2,
h3,
h4,
p,
a,
li {
  color: var(--text-on-bright);
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
}

p,
a,
li {
  font-size: 1.125rem;
  line-height: 1.8rem;
}

.text-on-dark {
  color: var(--text-on-dark-2);
}

.link-on-dark {
  transition: color 0.25s ease-in;
}

.link-on-dark:hover {
  color: var(--highlight-on-dark);
}

/*
* layout
*/

.center {
  max-width: 80rem;
  margin: auto;
  padding: 0 2rem;
}

.main-content {
  padding: 2rem;
}

.main-content p {
  max-width: 56rem;
  margin: 1rem 0;
  color: var(--text-on-bright);
  font-size: 1rem;
  line-height: 1.8rem;
}

.main-content h3 {
  margin: 3rem 0 2rem 0;
  color: var(--heading-on-bright);
  font-family: "Readex Pro", sans-serif;
  font-size: 1.875rem;
  font-weight: normal;
}

.main-content-highlighted {
  border-right: 10px var(--heading-on-bright) solid;
}

@media screen and (max-width: 64rem) {
  .main-content-highlighted {
    border-right: none;
  }
}

/*
* Buttons
*/

.button-container {
  overflow: hidden;
}

.button-on-dark {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  border: 2px var(--highlight-on-dark) solid;
  background: none;
  color: var(--highlight-on-dark);
  font-family: Barlow, sans-serif;
}

.button-on-dark:hover,
.button-on-dark:focus {
  color: #000;
}

.button-on-dark::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -1rem;
  background-color: var(--highlight-on-dark);
  width: calc(100% + 2rem);
  height: calc(100% + 4px);
  transform: skew(-10deg) translate3d(-100%, 0, 0);
  transition: all 250ms ease-out;
  z-index: -1;
}

.button-on-dark:hover::after,
.button-on-dark:focus::after {
  transform: translate3d(0%, 0, 0);
}

/*
* Hero section
*/

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("../images/torun.jpg") no-repeat;
  background-size: cover;
  background-color: var(--hero-bg-color);
  height: 100vh;
  text-align: center;
}

.skip-nav-link {
  position: absolute;
  left: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: var(--highlight-on-dark);
  border: none;
  color: #000;
  font-family: Barlow, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateY(-101%);
  transition: transform 250ms ease-in;
  z-index: 1;
}

.skip-nav-link:focus {
  transform: translateY(0);
}

/* dark blue background tint */
.hero::before {
  content: "";
  background-color: var(--hero-bg-color);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 75%;
}

/* background vignette */
.hero::after {
  content: "";
  background: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.6)
  );
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
}

.hero-top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  z-index: 1;
}

.menu {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 100%;
}

@media screen and (max-width: 320px) {
  .menu {
    gap: 1rem;
  }
}

.menu a {
  display: block;
  position: relative;
  padding: 0.4em 0;
  overflow: hidden;
  color: var(--text-on-dark-2);
  font-family: Barlow, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-variant: small-caps;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (min-width: 640px) {
  .menu {
    gap: 2rem;
    margin-left: 5%;
    width: unset;
  }

  .menu a {
    font-size: 1.125rem;
  }
}

.menu a:hover,
.menu a:focus {
  color: var(--highlight-on-dark);
  transition: color var(--menu-active-time);
}

.menu a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.2em;
  width: 100%;
  background-color: var(--highlight-on-dark);
  opacity: 1;
  transform: translate3d(-101%, 0, 0);
  transition: opacity var(--menu-active-time), transform var(--menu-active-time);
}

.menu a:hover::after,
.menu a:focus::after {
  transform: translate3d(0, 0, 0);
}

.hero-top .button-container {
  margin: -3rem auto 0 auto;
  transform: translateY(6rem);
}

@media screen and (min-width: 640px) {
  .hero-top .button-container {
    margin-left: auto;
    margin-right: 5%;
    transform: translateY(1.5rem);
  }
}

.phone {
  min-width: 10rem;
  margin-right: 5%;
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-decoration: none;
}

.phone-icon {
  display: inline-block;
  position: relative;
  top: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  padding-right: 0.6rem;
  margin-right: 0.6rem;
  border-right: 1px var(--highlight-on-dark) solid;
  fill: var(--highlight-on-dark);
}

.button-on-dark:hover .phone-icon,
.button-on-dark:focus .phone-icon {
  fill: #000;
  border-right: 1px #000 solid;
}

.hero-logo {
  margin: 0 auto;
  z-index: 1;
}

.hero-logo-name {
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  font-size: clamp(1rem, 1rem + 6vw, 6rem);
  border-bottom: 4px var(--highlight-on-dark) solid;
  border-bottom-width: clamp(2px, 1px + 1vw/4, 4px);
}

.hero-logo-title {
  font-family: "Playfair Display";
  font-weight: 400;
  font-variant: small-caps;
  letter-spacing: 0.1em;
  font-size: clamp(1rem, 0.5rem + 4.5vw, 4rem);
}

.hero-logo-name,
.hero-logo-title {
  color: var(--text-on-dark-1);
}

.hero-contact {
  margin-bottom: 1rem;
  transform: translateY(-4rem);
  z-index: 1;
}

.hero-contact .text-on-dark {
  line-height: 1.8rem;
  margin: 0 1rem;
}

@media screen and (max-width: 320px) {
  .hero-contact .text-on-dark {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}

/*
* Main content section
*/

.main-content-boxed-list {
  display: flex;
  flex-wrap: wrap;
}

.main-content-boxed-list li {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 5rem;
  margin-bottom: 1.5rem;
  padding: 1rem 1.5rem;
  border-left: 10px var(--dark-accent-on-bright) solid;
  background-color: #f6f3f2;
  color: var(--dark-accent-on-bright);
  list-style: none;
  line-height: 1.5rem;
  transition: transform 350ms ease-in, opacity 350ms ease-in;
}

@media screen and (min-width: 800px) {
  .main-content-boxed-list li {
    width: calc(50% - 0.75rem);
    margin-right: 0;
  }

  .main-content-boxed-list li:nth-child(odd) {
    margin-right: 1.5rem;
    transition-delay: 200ms;
  }
}

.contact-container {
  position: relative;
}

.contact-box {
  box-sizing: border-box;
  background-color: var(--dark-bg);
  padding: 2rem;
}

.contact-box h3 {
  color: var(--highlight-on-dark);
  font-size: 1.875rem;
  padding-bottom: 1rem;
}

.contact-box h4 {
  color: var(--text-on-dark-1);
  padding-bottom: 1rem;
  font-weight: 400;
  font-size: 1.125rem;
}

.contact-box p {
  color: var(--text-on-dark-2);
}

.contact-box-email {
  display: inline-block;
  padding-top: 1rem;
}

.contact-box-parking {
  padding-top: 1rem;
  font-size: 0.875rem;
  line-height: 1rem;
  opacity: 0.6;
}

.contact-map {
  width: 100%;
  height: 29rem;
}

@media screen and (min-width: 57rem) {
  .contact-box-container {
    top: 0;
    height: 29rem;
  }

  .contact-box {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: calc(50% + 1.5rem);
    background-color: var(--dark-bg);
    height: calc(100% - 4rem);
    margin-top: 2rem;
    padding: 2rem;
  }

  .contact-box h3 {
    color: var(--highlight-on-dark);
    font-size: 1.875rem;
    padding-bottom: 1rem;
  }

  .contact-box h4 {
    color: var(--text-on-dark-1);
    padding-bottom: 1rem;
    font-weight: 400;
    font-size: 1.125rem;
  }

  .contact-map {
    position: absolute;
    width: 100%;
    height: 29rem;
  }
}

/*
* Effects
*/

.slide-in--out {
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
}

.slide-in--in {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

footer {
  background-color: var(--dark-bg);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
}

.footer-content p {
  color: var(--text-on-dark-2);
  font-size: 0.875rem;
}

@media screen and (min-width: 57rem) {
  .footer-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
